<template>
  <v-container
    id="login"
    class="fill-height justify-center"
    tag="section"
  >
    <v-row justify="center">
      <v-slide-y-transition
        appear
      >
        <base-material-card
          :color="'primary'"
          light
          max-width="100%"
          width="450"
          class="px-5 py-3"
          style="background: rgba(255,255,255, .6) !important;"
        >
          <template v-slot:heading>
            <div class="text-center">
              <h1 class="display-2 font-weight-bold mb-2">
                Usuário sem permissão
              </h1>
            </div>
          </template>
          <v-spacer />
          <v-row
            justify="center"
          >
            <v-col
              cols="12"
            >
              <v-card-text>
                <v-col cols="12" sm="12" class="text-center">
                  <label class="red--text accent-4">
                    Você não possui acesso à página solicitada.
                  </label>
                </v-col>
                <v-row no-gutters>
                  <v-col cols="12" sm="6" class="text-center px-2">
                    <pages-btn
                      large block
                      color="primary"
                      depressed
                      class="v-btn--text white--text"
                      @click="goToHome"
                    >
                      Página inicial
                    </pages-btn>
                  </v-col>
                  <v-col cols="12" sm="6" class="text-center px-2">
                    <pages-btn
                      large block
                      color="primary"
                      depressed
                      class="v-btn--text white--text"
                      @click="logout"
                    >
                      Login
                    </pages-btn>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-col>
          </v-row>
        </base-material-card>
      </v-slide-y-transition>
    </v-row>
  </v-container>
</template>

<script>
export default {
  
  components: {
    PagesBtn: () => import('@/components/pages/Btn'),
  },

  methods: {
    goToHome: function() {
      this.$router.replace({ name: 'home' });
    },
    logout: function() {
      this.$router.replace({ name: 'Logout' });
    }
  }

}
</script>
